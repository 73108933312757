<template>
  <section class="featureButtons">
    <div
      :class="[`featureButtons__feature featureButtons__feature--${item.key}`, item.selected && 'selected']"
      v-for="item in features"
      :key="item.key"
    >
      <span>{{ item.name }}</span>
    </div>
  </section>
</template>

<script>
export default {
  props: ["record"],
  data() {
    return {
      dicDates: {
        priority: { name: "Prioridad", value: "high" },
        satelite: { name: "Satélite", value: "satelite" },
        gender: { name: "Masculino", value: "male" },
      },
    };
  },
  computed: {
    features() {
      return Object.entries(this.dicDates).map(([key, { name, value }]) => ({
        key,
        name,
        value,
        selected: this.record[key] === value,
      }));
    },
  },
};
</script>

<style lang="scss">
.featureButtons {
  @include Flex(row, flex-start);
  flex-wrap: wrap;
  gap: 9px;
  &__feature {
    @include Flex(row);
    height: 24px;
    padding: 0 7px;
    font-size: 10px;
    border-radius: 14px;
    border: 1px solid #e5e5e5;
    user-select: none;
    cursor: pointer;
    &--priority {
      color: #2bbb6f;
      border-color: #2bbb6f;
      &.selected {
        background-color: #2bbb6f2b;
      }
    }
    &--satelite {
      color: #ff8c00;
      border-color: #ff8c00;
      &.selected {
        background-color: #ff8c002b;
      }
    }
    &--gender {
      color: #4d9bff;
      border-color: #4d9bff;
      &.selected {
        background-color: #4d9bff2b;
      }
    }
  }
}
</style>
